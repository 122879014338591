<script>
	let page_url = window.location
	let report_url = `https://www.surveymonkey.com/r/M8XWWCN?Acc=true&SourceUrl=${page_url}`

</script>

<svelte:options tag="access-bar"></svelte:options>

<div class="container grey">
	<div class="row">
		<a href={report_url} class="link">
			<svg version="1.1" class="header-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 122.9 122.9" style="enable-background:new 0 0 122.9 122.9;" xml:space="preserve">
	
<path class="acc-icon" d="M61.4,0c33.9,0,61.5,27.5,61.5,61.4s-27.5,61.5-61.4,61.5C27.5,122.9,0,95.4,0,61.5C0,45.2,6.5,29.5,18,18
	C29.5,6.5,45.1,0,61.4,0z M61,74.2l-9,24.7c-0.4,1.3-1.4,2.3-2.6,2.8c-2.5,1.2-5.5,0.1-6.6-2.4c-0.6-1.2-0.6-2.6-0.2-3.8l6.2-17.3
	c0.5-1.3,0.9-2.6,1.2-4c0.3-1.4,0.4-2.8,0.5-4.2c0.2-2.5,0.4-5.3,0.5-7.9s0.2-5.2,0.3-7.3c0.1-2.6-0.6-2.8-2.7-3.3l-0.4-0.1l-18-3.4
	c-1.3-0.2-2.5-1-3.2-2.1c-1.6-2.3-1-5.4,1.3-7c1.1-0.8,2.4-1,3.8-0.8l19.3,3.6c0.8,0.1,1.5,0.2,2.3,0.2c2.4,0.3,4.8,0.5,7.2,0.5
	c3-0.1,6-0.3,8.9-0.7c0.9-0.1,1.8-0.2,2.6-0.3l18.2-3.4c1.3-0.3,2.6,0,3.8,0.7c2.3,1.5,2.9,4.7,1.3,6.9c0,0,0,0,0,0.1
	c-0.8,1.1-1.9,1.8-3.2,2.1l-17.4,3.3c-0.6,0.1-1.1,0.2-1.6,0.3c-1.8,0.3-2.7,0.5-2.6,3.1c0.1,1.9,0.3,4.2,0.6,6.5
	c0.3,2.8,0.8,5.7,1.3,8.4c0.3,1.8,0.6,3.2,1,4.6s0.8,2.8,1.4,4.4l6.1,16.9c0.9,2.6-0.4,5.5-3,6.4c-1.3,0.5-2.6,0.4-3.8-0.2
	c-1.2-0.6-2.1-1.6-2.6-2.8l-9-24.7l-1-1.8L61,74.2L61,74.2z M61.4,20.7c4.9,0,8.8,3.9,8.8,8.8c0,4.9-3.9,8.8-8.8,8.8
	c-4.9,0-8.8-3.9-8.8-8.8c0-2.3,0.9-4.6,2.6-6.2C56.9,21.6,59.1,20.7,61.4,20.7z M97.8,25.1C77.7,5,45.2,4.9,25.1,25
	S4.9,77.6,25,97.7s52.6,20.2,72.7,0.1c9.7-9.6,15.1-22.7,15.1-36.4C112.8,47.8,107.4,34.7,97.8,25.1z"/>
</svg>Report an accessibility issue.</a>
	</div>
</div>
 
<style> 
	.container {
		/* position:fixed !important;
		top: 0;
		left: 0;
		right: 0; */
		z-index: 99999;
		padding: 0 1rem .5rem ;
	}
	.container.grey {
		background:#222;
	}
	.row {
		text-align: right;
		max-width: 1366px;
		margin: 0 auto;
	}
	.link {
		color: #ececec;
		font-size: 12px;
	}
	.header-icon {
		max-width: 1rem;
		margin-right: .5rem;
		position: relative;
		top:.25rem;
	}
	/* .header-title {
		margin-right: .5rem;
		font-size: 14px;
	} */
	svg { 
		fill: #fff;
	}
	

	.link:hover,
	.link:focus
	 {
		color: yellow;
	}

	.link:hover .header-icon,
	.link:focus .header-icon
	 {
		fill: yellow;
	}
	
</style>